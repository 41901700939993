<template>
  <div id="segmentListPage">
    <!-- Header -->
    <div class="header pb-2">
      Saved Segments
      <span v-if="!fetchingData">({{ pageOptions.total }})</span>

      <div class="filters" style="float: right">
        <el-input class="searchInput" prefix-icon="el-icon-search" size="small" v-model="searchTerm" @input="onSearch" placeholder="Search Segment By Name"> </el-input>
        <router-link to="/segment/create">
          <el-button type="primary" size="small"> Create Segment </el-button>
        </router-link>
      </div>
    </div>

    <!-- Segment List -->
    <div v-if="!fetchingData && segmentList.length > 0" class="overflow-hidden mt-2 w-full border border-gray-100 sm:rounded-lg mb-4">
      <!-- Header -->
      <div class="flex bg-gray-50 w-full border-b">
        <div class="flex-none w-8">&nbsp;</div>
        <div class="flex-grow pl-1 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Segment Description</div>
        <div class="flex-none w-36 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Customers</div>
        <div class="flex-none w-32 py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900">Query Time</div>
        <div class="flex-none w-20">&nbsp;</div>
      </div>

      <template v-for="(segment, index) in segmentList">
        <div :key="index" class="border-b bg-white">
          <div class="flex w-full cursor-pointer hover:bg-gray-50">
            <div class="flex-none w-10 text-center py-3" @click="onShowSegmentDetails(index)">
              <div class="cursor-pointer text-sm" v-if="segment.execution && segment.execution.stage == 'complete'" @click="onShowSegmentDetails(index)">
                <i class="el-icon-arrow-down" v-if="currentActive == index" />
                <i class="el-icon-arrow-right" v-if="currentActive != index" />
              </div>
            </div>

            <!-- Segment Name -->
            <div class="flex-grow pl-0 pr-3 py-3 text-gray-600 align-top" @click="onShowSegmentDetails(index)">
              <div class="text-sm font-bold pb-1" v-html="segment.name" />
              <div v-html="segment.text" class="text-xs text-gray-500" style="font-size: 13px; line-height: 19px" />
            </div>

            <!-- Customer Count -->
            <div class="flex-none w-36 whitespace-nowrap text-sm px-3 py-3 text-gray-500 align-top align-top" @click="onShowSegmentDetails(index)">
              <template v-if="segment.execution">
                <template v-if="segment.execution.stage == 'running'">
                  <i class="fa fa-refresh fa-spin text-md text-primary-400"></i>
                </template>
                <span v-if="segment.execution.stage == 'complete'">{{ getNumberWithComma(segment.execution.result.total) }}</span>
                <span class="text-red-500 text-xs font-bold" v-if="segment.execution.stage == 'failed'">FAILED</span>
              </template>
              <span v-else>--</span>
            </div>
            <div class="flex-none w-32 whitespace-nowrap py-3 pl-2 pr-3 text-xs font-base text-gray-700 text-left leading-5 align-top" @click="onShowSegmentDetails(index)">
              <template v-if="segment.execution">
                {{ moment(segment.execution.query_time, 'x').format('DD/MM/YYYY') }}<br />
                {{ moment(segment.execution.query_time, 'x').format('hh:mm A') }}
              </template>
              <span v-else>--</span>
            </div>
            <div class="flex-none w-20 whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium text-primary-600 align-top">
              <a v-if="!segment.execution || segment.execution.stage != 'running'" @click="runSegmentQuery(index)">
                <el-tooltip content="Run Query" placement="left">
                  <!-- <i class="el-icon-refresh-right text-lg"></i> -->
                  <i class="text-sm fa-regular fa-arrow-rotate-right"></i>
                </el-tooltip>
              </a>
              <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbsp;&nbsp;
              <!-- Options Dropdown -->
              <el-dropdown @command="onSegmentOptionClick(index, ...arguments)">
                <span class="el-dropdown-link text-primary-600 text-base"><i class="el-icon-more" style="transform: rotate(90deg)"></i> </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="edit"> <i class="fa-regular fa-pen-to-square text-primary-600"></i> Edit Segment </el-dropdown-item>
                  <el-dropdown-item command="copy"> <i class="fa-solid fa-copy text-primary-600"></i> Create Duplicate </el-dropdown-item>
                  <el-dropdown-item command="export" divided> <i class="fa-solid fa-cloud-arrow-down text-primary-600"></i> Export Segment Users </el-dropdown-item>
                  <el-dropdown-item command="delete" divided> <i class="fa-regular fa-trash-can text-red-500"></i> Delete Segment </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              &nbsp;&nbsp;
            </div>
          </div>

          <!-- Segment Details -->
          <div class="bg-gray-50 mb-3 mx-10 border p-3 rounded-md" v-if="index == currentActive && segment.execution && segment.execution.stage == 'complete'">
            <div class="grid grid-cols-4 gap-4">
              <div>
                <div class="text-xs text-gray-500">Total Customers</div>
                <div class="text-md text-gray-800">{{ getNumberWithComma(segment.execution.result.total) }}</div>
              </div>
              <div>
                <div class="text-xs text-gray-500">Registered Customers</div>
                <div class="text-md text-gray-800">{{ getNumberWithComma(segment.execution.result.registered) }}</div>
              </div>
              <div>
                <div class="text-xs text-gray-500">Reachablity On Email</div>
                <div class="text-md text-gray-800">{{ getNumberWithComma(segment.execution.result.reachablity.email) }}</div>
              </div>
              <div>
                <div class="text-xs text-gray-500">Reachablity On SMS</div>
                <div class="text-md text-gray-800">{{ getNumberWithComma(segment.execution.result.reachablity.sms) }}</div>
              </div>
              <div>
                <div class="text-xs text-gray-500">Reachablity On WhatsApp</div>
                <div class="text-md text-gray-800">{{ getNumberWithComma(segment.execution.result.reachablity.whatsApp) }}</div>
              </div>
              <div>
                <div class="text-xs text-gray-500">Reachablity On Web Push</div>
                <div class="text-md text-gray-800">{{ getNumberWithComma(segment.execution.result.reachablity.webPush) }}</div>
              </div>
              <div>
                <div class="text-xs text-gray-500">Reachablity On Mobile Push</div>
                <div class="text-md text-gray-800">{{ getNumberWithComma(segment.execution.result.reachablity.mobilePush) }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Bottom Pagination -->
      <div class="pagination pagination-no-borders">
        <div class="totalCount">Total {{ pageOptions.total }} Segments</div>
        <el-pagination @current-change="fetchSegmentList" :current-page.sync="pageOptions.page" :page-size="pageOptions.limit" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
      </div>
    </div>

    <!-- Loader -->
    <div class="w-full border rounded-md overflow-hidden" v-if="fetchingData && segmentList" v-loading="true" style="height: 500px"></div>

    <!-- No Data -->
    <div class="bg-white text-center border rounded-md" v-if="!fetchingData && segmentList.length == 0">
      <div class="pt-36 pb-4 text-gray-500 text-base">No Segments Found</div>

      <router-link to="/segment/create">
        <el-button class="createSegmentButton" type="primary"> Create Segment </el-button>
      </router-link>

      <div class="text-xs text-gray-500 pt-10 pb-20 leading-5">
        Build dynamic user groups that enable you to deliver contextual brand experiences <br />
        personalized to each user’s traits & actions in real-time, across your platforms & channels.
      </div>
    </div>

    <ExportSegmentUsersPopup ref="exportSegmentUsersPopup"></ExportSegmentUsersPopup>
  </div>
</template>
<style lang="scss" src="./liveSegmentList.scss"></style>

<script>
import liveSegmentListComponent from './liveSegmentListComponent';
export default liveSegmentListComponent;
</script>
