import segmentModule from '@/services/sessionFilter';
import segmentFormatter from '@/services/segmentFormatter';
import segmentExecutionModule from '@/services/segmentExecution';

import moment from 'moment';
import customerServices from '@/services/customer';
const _ = require('lodash');
import ExportSegmentUsersPopup from '@/pages/segmentation/exportSegmentUsersPopup/exportSegmentUsersPopup';

export default {
  components: {
    ExportSegmentUsersPopup
  },
  name: 'liveSegmentList',
  data() {
    return {
      moment: moment,
      fetchingData: false,

      currentTab: 'active',
      searchTerm: '',
      pageOptions: {
        limit: 20,
        page: 1,
        total: null
      },

      segmentList: [],

      customerMetadata: null,
      currentActive: -1
    };
  },

  methods: {
    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch();
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.page = 1;
      this.fetchSegmentList();
    },

    formatSegmentForDisplaying(segment) {
      segment.data = JSON.parse(segment.data);
      segment.text = segmentFormatter.convertToString(segment.data, this.customerMetadata);
      if (segment.execution && segment.execution.stage == 'complete') {
        segment.execution.result = JSON.parse(segment.execution.result);
      }
      return segment;
    },

    onEditSegment(index) {
      let segmentId = this.segmentList[index].id;
      this.$router.push(`/segment/${segmentId}/update`);
    },

    cloneSegment(index) {
      let segment = this.segmentList[index];
      let requestId = 'create-segment-' + Math.random().toString(16).slice(2);
      window.sessionStorage.setItem(requestId, JSON.stringify(segment.data));
      // Redirect to create segment page with requst id.
      this.$router.push('/segment/create?request=' + requestId);
    },

    async fetchSegmentList() {
      this.fetchingData = true;
      try {
        let params = {
          getDeleted: this.currentTab == 'active' ? false : true,
          pageOptions: this.pageOptions,
          searchTerm: this.searchTerm
        };
        let result = await segmentModule.getSegmentsForListPage(params);
        this.segmentList = result.data.list;
        this.pageOptions.total = result.data.count;

        result = await customerServices.fetchUserPropertyList(null, this);
        this.customerMetadata = result.data.data;

        for (let i = 0; i < this.segmentList.length; i++) {
          this.segmentList[i] = this.formatSegmentForDisplaying(this.segmentList[i]);
        }

        this.fetchingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch live segments! Please contact support.');
      }
    },

    onShowSegmentDetails(index) {
      let segment = this.segmentList[index];
      if (!segment.execution || segment.execution.stage != 'complete') return;

      if (index == this.currentActive) {
        this.currentActive = -1;
      } else {
        this.currentActive = index;
      }
    },

    async runSegmentQuery(index) {
      let segmentId = this.segmentList[index].id;
      let result = await segmentExecutionModule.executeQueryForSegment(segmentId);
      this.segmentList[index].execution = result.data;
      console.log('Setting execuiton for given segment', result.data);
      this.$forceUpdate();
    },

    onSegmentOptionClick(index, command) {
      if (command == 'delete') {
        this.deleteSegment(index);
      } else if (command == 'edit') {
        this.onEditSegment(index);
      } else if (command == 'copy') {
        this.cloneSegment(index);
      } else if (command == 'export') {
        this.onExportSegmentUsers(index);
      }
      console.log('option at', index, command);
    },

    onExportSegmentUsers(index) {
      let segmentId = this.segmentList[index].id;
      let query = this.segmentList[index].data;
      this.$refs.exportSegmentUsersPopup.showDialog(query, segmentId);
    },
    // Delete Segment
    deleteSegment(index) {
      let segmentId = this.segmentList[index].id;
      this.$swal({
        title: `Delete Segment?`,
        text: "Are you sure you want to delete segment? You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(() => {
        setTimeout(async () => {
          try {
            await segmentModule.deleteSegment(segmentId, this);
            this.segmentList.splice(index, 1);
            this.successToast('Segment Deleted.');
          } catch (e) {
            this.reportError(e);
            this.errorToast(`Failed to delete segment. Something went wrong.`);
          }
        }, 50);
      });
    },

    async startPusherListener() {
      let pusher = new window.Pusher('4a955ea4744e1d73e579', {
        cluster: 'ap2'
      });

      let channelName = 'dashboard-' + this.$store.state.token.companyId;
      this.pubsubChannel = pusher.subscribe(channelName);
      this.pubsubChannel.bind('segment-result', (execution) => {
        console.log('Segment executed', execution);

        let index = _.findIndex(this.segmentList, (segment) => {
          return segment.execution && segment.execution.id == execution.id;
        });
        if (index >= 0) {
          this.segmentList[index].execution.stage = execution.stage;
          this.segmentList[index].execution.result = JSON.parse(execution.result);
          this.$forceUpdate();
        }
      });
    }
  },

  mounted() {
    this.fetchSegmentList();
    this.startPusherListener();
  },
  beforeDestroy() {
    if (this.pubsubChannel) {
      this.pubsubChannel.unbind('segment-result', null);
    }
  }
};
